<template>
  <div id="id-capture-cam">
    <main id="ocr">
      <div class="w-full text-4xl absolute text-black font-bold text-center z-50 top-[3%]">Front ID</div>
      <div v-if="!loading" class="frame"></div>
      <canvas id="camera--sensor" ref="cameraSensorId"></canvas>
      <video id="camera--view" autoplay playsinline ref="cameraViewId"></video>
      <img :src="imageSrc" alt="" id="camera--output" ref="cameraOutputId" :class="{hidden: !imageCaptured}">
      <button class="z-50" type="button" @click="takeIdPicture" id="camera--trigger" :disabled="loading">
        <span v-if="!loading">Take a picture</span>
        <span v-else>Loading...</span>
      </button>
      <div v-if="loading" class="full-screen-overlay">
        <div class="loader z-50"></div>
      </div>
      <input type="hidden" name="base64FaceId" ref="base64FaceId">
    </main>
  </div>
</template>

<script>
import axios from 'axios';
require('@/assets/ekyc-camera.css');
import store from '../store';

export default {
  props: {
    errorCount:{
      type: Number,
      required: true
    },
  },
  name: "IDCaptureCamera",
  data() {
    return {
      loading: false,
      constraints: {
        video: {
          facingMode: "environment",
          width: { min: 520, ideal: 1280, max: 1280 },
          height: { min: 520, ideal: 720, max: 1280 }
        },
        audio: false
      },
      trackId: null,
      base64IdData: "",
      imageSrc: '',
      imageCaptured: false,
      mediaStream: null,
    };
  },
  mounted() {
    const journeyId = sessionStorage.getItem('journeyId');
    if (!journeyId) {
      this.$router.push({ name: 'start-ekyc' });
    }
    this.startCamera();
  },
  beforeDestroy() {
    this.closeCamera();
  },
  methods: {
    resetCameraState() {
        // Reset camera
        if (this.mediaStream) {
            this.closeCamera();
        }
        // Reset image states
        this.imageCaptured = false;
        this.imageSrc = '';
        this.base64IdData = '';
    },
    closeCamera() {
      let tracks = this.mediaStream.getTracks();
      tracks.forEach(track => track.stop() )
    },
    startCamera() {
      navigator.mediaDevices
          .getUserMedia(this.constraints)
          .then(stream => {
            this.mediaStream = stream;
            this.trackId = stream.getTracks()[0];
            this.$refs.cameraViewId.srcObject = stream;
          })
          .catch(error => {
            console.error("Oops. Something is broken.", error);
          });
    },
    async takeIdPicture() {
      // Mixpanel tracking
      if (process.env.VUE_APP_ENV === 'production'){
        try {
          this.$mixpanel.track('E-Kyc ID Front Image Captured', {
            userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
            current_form: 'E-Kyc ID Front Capture Camera',
            selected_document_type: sessionStorage.getItem('documentType'),
            journey_id: sessionStorage.getItem('journeyId')
          });
        } catch (error) {
          console.log('Mixpanel tracking error: ', error);
        }
      }

      this.loading = true;

      const cameraViewId = this.$refs.cameraViewId;
      const cameraSensorId = this.$refs.cameraSensorId;
      const cameraOutputId = this.$refs.cameraOutputId;

      cameraSensorId.width = cameraViewId.videoWidth;
      cameraSensorId.height = cameraViewId.videoHeight;

      cameraSensorId.getContext("2d").drawImage(cameraViewId, 0, 0);

      const imageDataUrl = cameraSensorId.toDataURL("image/jpeg", 1.0);
      cameraOutputId.src = imageDataUrl;
      cameraOutputId.classList.add("taken");
      this.imageCaptured = true;

      this.base64IdData = imageDataUrl.replace(/^data:image\/[^;]+;base64,/, '');

      sessionStorage.setItem('docFront', this.base64IdData);

      const documentType = sessionStorage.getItem('documentType');
      this.closeCamera();

      if (documentType === "passport") {
        const journeyId = sessionStorage.getItem('journeyId');

        try {
          let response = await axios.post('/api/centralize-okay-id', {
            journeyId,
            docFront: this.base64IdData,
            docBack: null // Since it's a passport, docBack is not required
          });
          if (response.data && response.data.status === 'success') {
            this.$toast.success('Front ID Captured Successfully');
            this.$emit('next-step', true);
          } else {
            // Mixpanel tracking
            if (process.env.VUE_APP_ENV === 'production'){
              try {
                this.$mixpanel.track('E-Kyc ID Front Image captured unsuccessfully due to inappropriate image format', {
                  userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
                  current_form: 'E-Kyc ID Front Capture Camera',
                  selected_document_type: sessionStorage.getItem('documentType'),
                  journey_id: sessionStorage.getItem('journeyId')
                });
              } catch (error) {
                console.log('Mixpanel tracking error: ', error);
              }
            }

            this.$toast.error('Inappropriate image format, please retake');
            this.handleError();
          }
        } catch (error) {
          // Mixpanel tracking
          if (process.env.VUE_APP_ENV === 'production'){
            try {
              this.$mixpanel.track('E-Kyc ID Front Image captured unsuccessfully due to error centralizing Okay ID', {
                userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
                current_form: 'E-Kyc ID Front Capture Camera',
                selected_document_type: sessionStorage.getItem('documentType'),
                journey_id: sessionStorage.getItem('journeyId')
              });
            } catch (error) {
              console.log('Mixpanel tracking error: ', error);
            }
          }

          console.error('Error centralizing Okay ID:', error);
          this.handleError();
        } finally {
          this.loading = false;
        }
      } else {
        this.$toast.success('Front ID Captured Successfully');
        this.$emit('next-step');
        this.loading = false;
      }
    },
    handleError() {
      console.log(this.errorCount);
      if (this.errorCount >= 1) {
        this.closeCamera();
        this.$toast.error('Exceeded maximum attempt.');
        const journeyId = sessionStorage.getItem('journeyId');
        if (journeyId){
          this.$router.push({ name: 'document-summary', query: { JID : journeyId } });
        }else {
          console.error('Journey ID is missing.');
        }
      } else {
        this.resetCameraState();
        this.startCamera();
        this.$emit('failed', { type:"doc" });
      }
    }
  }
};
</script>
