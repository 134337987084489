// src/composables/useSettings.js
import Vue from 'vue'

const state = Vue.observable({
    settings: null,
    error: null
})

const settings = {
    async fetchSettings() {
        try {
            const response = await Vue.prototype.$http.get('/api/settings')
            state.settings = {
                enable_ekyc: Boolean(response.data.enable_ekyc)
            }
            return state.settings
        } catch (error) {
            state.error = error
            throw error
        }
    },
    isEkycEnabled() {
        return Boolean(state.settings?.enable_ekyc)
    },
    getSettings() {
        return state.settings
    },
    canRetryEkyc() {
        const retryCount = parseInt(localStorage.getItem('retryCount') || '0')
        return retryCount < 1
    }
}

export default settings