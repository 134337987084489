<template>
  <div id="id-back-capture-cam">
    <main id="ocr">
      <div class="w-full text-4xl absolute text-black font-bold text-center z-50 top-[3%]">Back ID</div>
      <div v-if="!loading" class="frame"></div>
      <canvas id="camera--sensor" ref="cameraSensorId"></canvas>
      <video id="camera--view" autoplay playsinline ref="cameraViewId"></video>
      <img :src="imageSrc" alt="" id="camera--output" ref="cameraOutputId" :class="{ hidden: !imageCaptured }">
      <button class="z-50" type="button" @click="takeIdPicture" id="camera--trigger" :disabled="loading">
        <span v-if="!loading">Take a picture</span>
        <span v-else>Loading...</span>
      </button>
      <div v-if="loading" class="full-screen-overlay">
        <div class="loader z-50"></div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import store from '../store';
require('@/assets/ekyc-camera.css');

export default {
  name: "IDBackCaptureCamera",
  props: {
    errorCount:{
      type: Number,
      required: true
    },
  },
  data() {
    return {
      loading: false,
      constraints: {
        video: {
          facingMode: "environment",
          width: { min: 520, ideal: 1280, max: 1280 },
          height: { min: 520, ideal: 720, max: 1280 }
        },
        audio: false
      },
      trackId: null,
      base64IdData: "",
      imageSrc: '',
      imageCaptured: false,
      mediaStream: null,
    };
  },
  mounted() {
    console.log(this.errorCount)
    const journeyId = sessionStorage.getItem('journeyId');
    if (!journeyId) {
      this.$router.push({ name: 'start-ekyc' });
    }
    this.startCamera();
  },
  beforeDestroy() {
    this.closeCamera();
  },
  methods: {
    startCamera() {
      navigator.mediaDevices
          .getUserMedia(this.constraints)
          .then(stream => {
            this.mediaStream = stream;
            this.trackId = stream.getTracks()[0];
            this.$refs.cameraViewId.srcObject = stream;
          })
          .catch(error => {
            console.error("Oops. Something is broken.", error);
          });
    },
    closeCamera() {
      let tracks = this.mediaStream.getTracks();
      tracks.forEach(track => track.stop() )
    },
    async takeIdPicture() {
      // Mixpanel tracking
      if (process.env.VUE_APP_ENV === 'production'){
        try {
          this.$mixpanel.track('E-Kyc ID Back Image Captured', {
            userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
            current_form: 'E-Kyc ID Back Capture Camera',
            selected_document_type: sessionStorage.getItem('documentType'),
            journey_id: sessionStorage.getItem('journeyId')
          });
        } catch (error) {
          console.log('Mixpanel tracking error: ', error);
        }
      }

      this.loading = true;

      const cameraViewId = this.$refs.cameraViewId;
      const cameraSensorId = this.$refs.cameraSensorId;

      cameraSensorId.width = cameraViewId.videoWidth;
      cameraSensorId.height = cameraViewId.videoHeight;

      cameraSensorId.getContext("2d").drawImage(cameraViewId, 0, 0);

      const imageDataUrl = cameraSensorId.toDataURL("image/jpeg", 1.0);
      this.imageSrc = imageDataUrl;
      this.imageCaptured = true;

      this.base64IdData = imageDataUrl.replace(/^data:image\/[^;]+;base64,/, '');

      const journeyId = sessionStorage.getItem('journeyId');
      const docFront = sessionStorage.getItem('docFront');

      this.closeCamera();

      try {
        let response = await axios.post('/api/centralize-okay-id', {
          journeyId,
          docFront,
          docBack: this.base64IdData
        });

        if (response.data && response.data.status === 'success') {
          this.$toast.success('Back ID Captured Successfully');
          this.$emit('next-step', true);
        } else {
          // Mixpanel tracking
          if (process.env.VUE_APP_ENV === 'production'){
            try {
              this.$mixpanel.track('E-Kyc ID Back Image captured unsuccessfully due to inappropriate image format', {
                userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
                current_form: 'E-Kyc ID Back Capture Camera',
                selected_document_type: sessionStorage.getItem('documentType'),
                journey_id: sessionStorage.getItem('journeyId')
              });
            } catch (error) {
              console.log('Mixpanel tracking error: ', error);
            }
          }

          this.$toast.error('Inappropriate image format, please retake');
          this.handleError();
        }
      } catch (error) {
        // Mixpanel tracking
        if (process.env.VUE_APP_ENV === 'production'){
          try {
            this.$mixpanel.track('E-Kyc ID Back Image captured unsuccessfully due to error centralizing Okay ID', {
              userId_Name: `${store.state.auth.user.id} ${store.state.auth.user.name}`,
              current_form: 'E-Kyc ID Back Capture Camera',
              selected_document_type: sessionStorage.getItem('documentType'),
              journey_id: sessionStorage.getItem('journeyId')
            });
          } catch (error) {
            console.log('Mixpanel tracking error: ', error);
          }
        }

        console.error('Error centralizing Okay ID:', error);
        this.$toast.error('Please attempt again, ensure the document/selfie is clear and not obstructed'); 
        this.handleError();
      } finally {
        this.loading = false;
      }
    },
    handleError() {
      const key = this.$route.query.key;

      if (this.errorCount >= 1) {
        this.closeCamera();
        this.$toast.error('Exceeded maximum attempt.');
        const journeyId = sessionStorage.getItem('journeyId');
        if (journeyId){
          this.$router.push({ name: 'document-summary', query: { JID : journeyId, key } });
        }else {
          console.error('Journey ID is missing.');
        }
      } else {
        this.$emit('failed', { type:"doc" });
      }
    }
  }
};
</script>
