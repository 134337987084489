<template>
 <div class="p-6 bg-gray-100 min-h-screen content-center flex flex-col gap-10 items-center justify-center">
    <div class="flex flex-col gap-6">
        <!-- Do's and Don't -->
        <div class="flex flex-col lg:flex-row gap-10">
            <!-- Do's -->
            <div class="flex flex-col px-2 gap-2 lg:gap-6 basis-1/2">
                <h4 class="text-left text-green-500 font-medium">Do's</h4>
                <div class="flex flex-col lg:flex-row gap-4 lg:gap-20">
                    <!-- Icon -->
                    <div>
                        <svg id="icon-idcardinfo" xmlns="http://www.w3.org/2000/svg" width="64.271" height="45.298" viewBox="0 0 64.271 45.298">
                            <g id="Icon-ID">
                                <path id="Path_998" data-name="Path 998" d="M50.247,0H4.753A4.759,4.759,0,0,0,0,4.753V32.166A4.759,4.759,0,0,0,4.753,36.92H50.247A4.759,4.759,0,0,0,55,32.166V4.753A4.759,4.759,0,0,0,50.247,0Zm3.12,32.167a3.124,3.124,0,0,1-3.121,3.121H4.753a3.124,3.124,0,0,1-3.12-3.121V4.753a3.124,3.124,0,0,1,3.12-3.121H50.247a3.124,3.124,0,0,1,3.121,3.121V32.167Z" transform="translate(9.271 8.377)" fill="#303030"/>
                                <path id="Path_1004" data-name="Path 1004" d="M368.155,131.13h.092a2.662,2.662,0,0,0,2.037-.882,5.278,5.278,0,0,0,.907-3.61,2.947,2.947,0,0,0-1.4-2.621,3.2,3.2,0,0,0-1.555-.407h-.049a3.217,3.217,0,0,0-1.555.4,2.947,2.947,0,0,0-1.418,2.633,5.286,5.286,0,0,0,.907,3.61A2.659,2.659,0,0,0,368.155,131.13Zm-2.171-4.418c0-.008,0-.017,0-.024a2.1,2.1,0,0,1,2.2-2.295h.035a2.112,2.112,0,0,1,2.2,2.295.065.065,0,0,0,0,.024,4.582,4.582,0,0,1-.713,3.021,1.891,1.891,0,0,1-1.488.619h-.028a1.885,1.885,0,0,1-1.485-.619A4.6,4.6,0,0,1,365.984,126.711Z" transform="translate(-317.378 -102.552)" fill="#303030"/>
                                <path id="Path_1005" data-name="Path 1005" d="M356.089,180.97v-.008c0-.024,0-.046,0-.072a2.265,2.265,0,0,0-1.31-2.338l-.029-.008a8.4,8.4,0,0,1-2.4-1.092.39.39,0,0,0-.449.638,9.013,9.013,0,0,0,2.639,1.205c.673.24.748.96.769,1.619a.614.614,0,0,0,0,.072,5.134,5.134,0,0,1-.061.893,11.541,11.541,0,0,1-10.193,0,5.006,5.006,0,0,1-.061-.893c0-.024,0-.046,0-.072.021-.659.095-1.378.769-1.619a9.1,9.1,0,0,0,2.639-1.205.39.39,0,0,0-.449-.638,8.227,8.227,0,0,1-2.4,1.092l-.029.008a2.272,2.272,0,0,0-1.31,2.338.588.588,0,0,1,0,.072v.008a4.438,4.438,0,0,0,.148,1.31.372.372,0,0,0,.151.183,12.215,12.215,0,0,0,11.283,0,.387.387,0,0,0,.15-.183A4.552,4.552,0,0,0,356.089,180.97Z" transform="translate(-299.326 -149.826)" fill="#303030"/>
                                <g id="Group_8668" data-name="Group 8668" transform="translate(9.271)">
                                <line id="Line_128" data-name="Line 128" x2="42.055" transform="translate(6.499 3.501)" fill="none" stroke="#303030" stroke-width="2"/>
                                <path id="Path_1012" data-name="Path 1012" d="M17.521-12.489v7.545L10.576-8.716Z" transform="translate(-10.576 12.489)" fill="#303030"/>
                                <path id="Path_1013" data-name="Path 1013" d="M6.945,0V7.545L0,3.773Z" transform="translate(55 7.545) rotate(180)" fill="#303030"/>
                                </g>
                                <g id="Group_8669" data-name="Group 8669" transform="translate(7.545 8.323) rotate(90)">
                                <line id="Line_128-2" data-name="Line 128" x2="24.03" transform="translate(6.499 3.501)" fill="none" stroke="#303030" stroke-width="2"/>
                                <path id="Path_1012-2" data-name="Path 1012" d="M6.945,0V7.545L0,3.773Z" fill="#303030"/>
                                <path id="Path_1013-2" data-name="Path 1013" d="M6.945,0V7.545L0,3.773Z" transform="translate(36.975 7.545) rotate(180)" fill="#303030"/>
                                </g>
                                <g id="Rectangle_417" data-name="Rectangle 417" transform="translate(42.271 17)" fill="none" stroke="#303030" stroke-linejoin="bevel" stroke-width="1">
                                <rect width="17" height="20" rx="1" stroke="none"/>
                                <rect x="0.5" y="0.5" width="16" height="19" rx="0.5" fill="none"/>
                                </g>
                                <line id="Line_129" data-name="Line 129" x2="22" transform="translate(13.771 24.501)" fill="none" stroke="#303030" stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_130" data-name="Line 130" x2="22" transform="translate(13.771 27.501)" fill="none" stroke="#303030" stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_131" data-name="Line 131" x2="11" transform="translate(13.771 30.501)" fill="none" stroke="#303030" stroke-linecap="round" stroke-width="1"/>
                                <line id="Line_132" data-name="Line 132" x2="11" transform="translate(47.771 40.501)" fill="none" stroke="#303030" stroke-linecap="round" stroke-width="1"/>
                            </g>
                        </svg>
                    </div>

                    <!-- Text -->
                    <div class="w-full flex flex-col gap-4 items-start justify-center text-left font-medium">
                        <p>Ensure the images are clear</p>
                        <p>All information must be readable and clearly visible.<br>All corners of the ID document must be captured.</p>
                    </div>
                </div>
            </div>

            <!-- Don't -->
            <div class="flex flex-col px-2 gap-2 lg:gap-6 basis-1/2">
                <h4 class="text-left text-red-500 font-medium">Don't</h4>
                <div class="flex flex-col lg:flex-row gap-4 lg:gap-20">
                    <!-- Icon -->
                    <div>
                        <svg id="icon-noflashglare" xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
                            <g id="noun_No_Flash_2148" data-name="noun_No Flash_2148">
                                <path id="Path_1007" data-name="Path 1007" d="M40.562,39.869l2.757,3.439,3.264-3.439Z" transform="translate(-20.529 -10.944)" fill="#303030"/>
                                <path id="Path_1008" data-name="Path 1008" d="M79.412,51.221l0,0Z" transform="translate(-43.417 -28.111)" fill="#303030"/>
                                <path id="Path_1009" data-name="Path 1009" d="M13.182,23.5l-2.91-2.06A24.057,24.057,0,0,0,8.239,24.39l0,0-.007,0a23.5,23.5,0,0,0-1.581,3.265A23.9,23.9,0,0,0,5,36.393C5,49.4,15.3,59.941,28.014,59.943A22.6,22.6,0,0,0,43.5,53.767l-2.979-2.111a19.16,19.16,0,0,1-12.512,4.677A19.742,19.742,0,0,1,8.525,36.393a20.573,20.573,0,0,1,2.619-9.94v0l0,0,6.442,4.564,1.131-3.584Z" transform="translate(-5 -14.943)" fill="#303030"/>
                                <path id="Path_1010" data-name="Path 1010" d="M63.326,54.7l-.122-.083-.036.083Z" transform="translate(-34.098 -29.813)" fill="#303030"/>
                                <path id="Path_1011" data-name="Path 1011" d="M35.4,5a22.535,22.535,0,0,0-15.259,5.963l3,2.127A19.107,19.107,0,0,1,35.4,8.606,19.73,19.73,0,0,1,54.871,28.541a20.6,20.6,0,0,1-2.462,9.672l-6.029-4.272-.163.166-2.369,2.425L50.429,41.2l2.9,2.059a24.029,24.029,0,0,0,5.063-14.715C58.393,15.538,48.1,5,35.4,5Z" transform="translate(-13.393 -5)" fill="#303030"/>
                                <line id="Line_135" data-name="Line 135" x1="8" transform="translate(18.06 20.811)" fill="none" stroke="#303030" stroke-linecap="round" stroke-width="1.5"/>
                                <line id="Line_136" data-name="Line 136" x1="2" y2="10" transform="translate(18.365 11.483)" fill="none" stroke="#303030" stroke-linecap="round" stroke-width="1.5"/>
                                <line id="Line_137" data-name="Line 137" x1="3" y2="10" transform="translate(22.787 20.233)" fill="none" stroke="#303030" stroke-linecap="round" stroke-width="1.5"/>
                            </g>
                        </svg>
                    </div>

                    <!-- Text -->
                    <div class="w-full flex flex-col gap-4 items-start justify-center text-left font-medium">
                        <p>Images with flash or glare light</p>
                        <p>Ensure that reflections, light glare, shadows, etc. do not appear in the image</p>
                    </div>
                </div>
            </div>

        </div>

        <h4 class="font-semibold text-left">Examples</h4>

        <div class="flex flex-col lg:flex-row gap-4 lg:gap-10">
            <!-- Do's Example -->
            <div class="flex flex-col px-2 gap-6 basis-1/2">
                <h4 class="text-left text-green-500 font-medium">Good</h4>
                <div class="flex flex-col lg:flex-row gap-8">
                    <!-- ID Front -->
                    <div class="flex items-center justify-center">
                        <img class="w-72" src="../../public/images/photoGuide/good-id-front.png" alt="Good ID Front Example" />
                    </div>

                    <!-- ID Back -->
                    <div class="flex items-center justify-center">
                        <img class="w-72" src="../../public/images/photoGuide/good-id-back.png" alt="Good ID Back Example" />
                    </div>
                </div>
            </div>

            <!-- Don't Example -->
            <div class="flex flex-col px-2 gap-6 basis-1/2">
                <h4 class="text-left text-red-500 font-medium">Bad</h4>
                <div class="flex flex-col lg:flex-row gap-8">
                    <!-- ID Front -->
                    <div class="flex items-center justify-center">
                        <img class="w-72" src="../../public/images/photoGuide/bad-id-front.png" alt="Bad ID Front Example" />
                    </div>

                    <!-- ID Back -->
                    <div class="flex items-center justify-center">
                        <img class="w-72" src="../../public/images/photoGuide/bad-id-back.png" alt="Bad ID Back Example" />
                    </div>
                </div>
            </div>
        </div>

        <p class="font-medium">Take a photo of your NRIC and upload (Front and Back images)</p>
        <div class="pt-5">
            <button class="px-8 py-4 text-white bg-blue-500" @click="emitNextStep" :disabled="loading">
            <span v-if="!loading">Next</span>
            <span v-else>Loading...</span>
            </button>
        </div>
    </div>
 </div>
</template>

<script>
export default  {
    name: "PhotoGuide",

    data() {
        return {
            loading: false
        }
    },
    
    methods: {
        emitNextStep() {
            this.loading = true;
            this.$emit('next-step', true);
        }
    }
}
</script>
